// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assemblaggio-schede-elettroniche-js": () => import("./../../../src/pages/assemblaggio-schede-elettroniche.js" /* webpackChunkName: "component---src-pages-assemblaggio-schede-elettroniche-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../../../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-grazie-js": () => import("./../../../src/pages/grazie.js" /* webpackChunkName: "component---src-pages-grazie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lavora-con-noi-js": () => import("./../../../src/pages/lavora-con-noi.js" /* webpackChunkName: "component---src-pages-lavora-con-noi-js" */),
  "component---src-pages-lavora-con-noi-stage-js": () => import("./../../../src/pages/lavora-con-noi/stage.js" /* webpackChunkName: "component---src-pages-lavora-con-noi-stage-js" */),
  "component---src-pages-prodotti-fiber-el-6-js": () => import("./../../../src/pages/prodotti/fiber-el6.js" /* webpackChunkName: "component---src-pages-prodotti-fiber-el-6-js" */),
  "component---src-pages-prodotti-hera-laundry-js": () => import("./../../../src/pages/prodotti/hera-laundry.js" /* webpackChunkName: "component---src-pages-prodotti-hera-laundry-js" */),
  "component---src-pages-prodotti-schede-rele-interfaccia-plc-js": () => import("./../../../src/pages/prodotti/schede-rele-interfaccia-plc.js" /* webpackChunkName: "component---src-pages-prodotti-schede-rele-interfaccia-plc-js" */),
  "component---src-pages-prodotti-sonde-di-temperatura-js": () => import("./../../../src/pages/prodotti/sonde-di-temperatura.js" /* webpackChunkName: "component---src-pages-prodotti-sonde-di-temperatura-js" */),
  "component---src-pages-questionario-soddisfazione-js": () => import("./../../../src/pages/questionario-soddisfazione.js" /* webpackChunkName: "component---src-pages-questionario-soddisfazione-js" */),
  "component---src-pages-servizi-progettazione-elettronica-nuovo-progetto-js": () => import("./../../../src/pages/servizi/progettazione-elettronica/nuovo-progetto.js" /* webpackChunkName: "component---src-pages-servizi-progettazione-elettronica-nuovo-progetto-js" */),
  "component---src-pages-servizi-sviluppo-firmware-js": () => import("./../../../src/pages/servizi/sviluppo-firmware.js" /* webpackChunkName: "component---src-pages-servizi-sviluppo-firmware-js" */),
  "component---src-pages-servizi-sviluppo-software-js": () => import("./../../../src/pages/servizi/sviluppo-software.js" /* webpackChunkName: "component---src-pages-servizi-sviluppo-software-js" */),
  "component---src-pages-settori-js": () => import("./../../../src/pages/settori.js" /* webpackChunkName: "component---src-pages-settori-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-career-template-js": () => import("./../../../src/templates/careerTemplate.js" /* webpackChunkName: "component---src-templates-career-template-js" */),
  "component---src-templates-funnel-template-js": () => import("./../../../src/templates/funnelTemplate.js" /* webpackChunkName: "component---src-templates-funnel-template-js" */),
  "component---src-templates-legal-template-js": () => import("./../../../src/templates/legalTemplate.js" /* webpackChunkName: "component---src-templates-legal-template-js" */),
  "component---src-templates-product-feature-template-js": () => import("./../../../src/templates/productFeatureTemplate.js" /* webpackChunkName: "component---src-templates-product-feature-template-js" */),
  "component---src-templates-service-template-js": () => import("./../../../src/templates/serviceTemplate.js" /* webpackChunkName: "component---src-templates-service-template-js" */)
}

